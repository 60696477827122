@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

.container__cards{
    max-width: 1200px;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.card{
    width: 300px;
    margin: 10px;
    transition: all 500ms;
    --bs-card-border-color: rgb(255 255 255 / 18%);
    -webkit-box-shadow: 0px 7px 14px 1px rgba(0,0,0,0.13);
    -moz-box-shadow: 0px 7px 14px 1px rgba(0,0,0,0.13);
    box-shadow: 0px 7px 14px 1px rgba(0,0,0,0.13);}

.card:hover{
    width: 350px;
}

.card .cover{
    width: 100%;
    height: 250px;
    position: relative;
    overflow: hidden;
}

.card .cover img{
    width: 250px;
    display: block;
    margin: auto;
    position: relative;
    top: 40px;
    z-index: 1;
    filter: drop-shadow(5px 5px 4px rgba(0,0,0,0.5));
    transition: all 700ms;
}

.card:hover .cover img{
    top: 0px;
    filter: none;
}

.card .img__back{
    width: 100%;
    height: 300px;
    position: absolute;
    bottom: -10px;
    left: 0;
    background-size: cover;
    border-radius: 20px;
    transition: all 700ms;
}

.card:nth-of-type(1) .img__back{
    background-image: url(https://i.ibb.co/nnxnsmh/Dise-o-sin-t-tulo-22.png);
}

.card:nth-of-type(2) .img__back{
    background-image: url(https://i.ibb.co/nnxnsmh/Dise-o-sin-t-tulo-22.png);
}

.card:nth-of-type(3) .img__back{
    background-image: url(https://i.ibb.co/nnxnsmh/Dise-o-sin-t-tulo-22.png);
}

.card:hover .img__back{
    bottom: -40px;
}

.card .description{
    background: white;
    margin-top: -10px;
    padding: 20px;
    border-radius: 0px 0px 20px 20px;
    transition: all 900ms;
}

.card:hover .description{
    padding: 30px;
}

.card .description h2{
    margin-top: 5px;
}

.card .description p{
    margin-top: 10px;
}

.card .description input{
    padding: 10px 40px;
    margin-top: 10px;
    border: none;
    background: #fc466b;
    color: white;
  
    cursor: pointer;
    border-radius: 8px;
    transition: all 500ms;
}

.card .description input:hover{
    background: #fd0638;
}