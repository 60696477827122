* {box-sizing: border-box;}

body { 
  margin: 0;
 
  
  font-family: 'Akatab';

}

.btn_responsive{
  display: none;
}
#navbar {
  overflow: hidden;
  background: linear-gradient(rgba(255, 255, 255, 0.925), rgba(255, 255, 255, 0.945));
  /* background-color: #e31d25; */
  padding: 40px 10px;
  transition: 0.4s;
  position: fixed;
  
  width: 100%;
  top: 0%;
  z-index: 99;
}



#navbar a {
  float: left;
  color: black;
  text-align: center;
  padding: 12px;
  margin: 0.5px;
  text-decoration: none;
  font-size: 16px; 
  line-height: 25px;
  border-radius: 4px;
}

#navbar #logo {
  transition: 0.4s;
}

#navbar a:hover {
  background-color: #ddd;
  color: black;
}

#navbar a.active {
  background-color: #e31d25;
  color: white;
}

#navbar-right {
  float: right;
}

@media screen and (max-width: 580px) {
  #navbar {
   display: none;
  }
  .btn_responsive{
    display: block;

  }
 .logoHeader{
  display: none;
 }
 .overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0, 0.9);
  overflow-x: hidden;
  transition: 0.5s;
}

.overlay-content {
  position: relative;
  top: 25%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.overlay a:hover, .overlay a:focus {
  color: #f1f1f1;
}

.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

}

.icon-bar {
  position: fixed;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 100;
}

/* Style the icon bar links */
.icon-bar a {
  display: block;
  text-align: center;
  padding: 16px;
  transition: all 0.3s ease;
  color: white;
  font-size: 20px;
}

/* Style the social media icons with color, if you want */
.icon-bar a:hover {
  background-color: #000;
}

.facebook {
  background: #3B5998;
  color: white;
}


.instagram {
  background: #4d9ddf;
  color: white;
}

.youtube {
  background: #bb0000;
  color: white;
}

.default {
  border: 2px solid black;
  border-color: #e0e0e063;
  color: black;
}


#up {
  
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;

  border: none;
  outline: none;
  background-color: rgba(255, 0, 0, 0.068);
  color: #e31d25;
  cursor: pointer;
  padding: 15px;
  border-radius: 4px;
  transition: 0.5s;
}

#up:hover {
  background-color: rgba(194, 194, 194, 0.397);
  color: #e31d25;
  transition: 1s;
}

.columns {
  float: left;
  width: 33.3%;
  padding: 8px;
  
}

.price {
  list-style-type: none;
  margin: 0;
  padding: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.price:hover {
  box-shadow: 0 8px 12px 0 rgba(0,0,0,0.2);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.price .header {
  background-color: #111;
  color: white;
  font-size: 18px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.price li {
  border-bottom: 1px solid #eee;
  padding: 20px;
  text-align: center;
  font-size: 15px;
}



.price .grey {
  background-color: #eee;
  font-size: 18px;

}

.button {
  background-color: #e31d25;
  border: none;
  color: white;
  padding: 10px 25px;
  text-align: center;
  text-decoration: none;
  font-size: 15px;
  border-radius: 4px;
}

@media only screen and (max-width: 600px) {
  .columns {
    width: 100%;
  }
}

hr.rounded {
  border-top: 4px solid #ff000062;
  border-radius: 5px;
}


