// fonts
$font-header: Montserrat, helvetica;
$font-nav : "Space Mono", monospace;
// colors
$color-dark: #111;
$color-border: #ccc;
$color-trans-bg: #ededed;
// easings
$ease-cb: cubic-bezier(.4,0,.2,1);
$ease-cb-2: cubic-bezier(.19,1,.22,1);
$ease-cb-3: cubic-bezier(.77,0,.175,1);
$ease-cb-4: cubic-bezier(.99,1,.92,1);
// padding
$pad: 3em;
// mqs
$mq-med: 54em;
$mq-large: 65em;
$mq-xlarge: 91em;


//------------------------------
//  nth-trans-delay()
//  Transition Delay on nth-childs
//  @param: $delay_items: number of nth-of-type items to create
//  @param: $delay_time: transition-dealy value
//-----------------------------
@mixin nth-trans-delay($delay_items: 7, $delay_time: 0.2s){
  @for $i from 1 through $delay_items {
    &:nth-child(#{$i}) {   
     transition-delay: $delay_time * $i; 
    }
  }
}

//-------------------------------
//  Body, prevent scrolling
//-------------------------------
body{
  height: 100%;
  overflow-y:hidden;
  font-family: 'Akatab';
}

//-------------------------------
//  Slides Nav
//-------------------------------
.slides-nav{
  z-index: 99;
  position: fixed;
  // Grimey, not sure why
  // 0 isn't working
  right: -5%;
  display: flex;
  align-items: center;
  height: 100%;
  color: #111;
  
  @media (min-width: $mq-med){
    right: 2%;
  }

  &__nav{
    position: relative;
    right: 0;
    display: block;
    font-size: 1em;
    transform: rotate(90deg);
    transform-origin: center;
  }

  button{
    position: relative;
    display:inline-block;
    padding: 0.35em;
    margin: 0;
    appearance: none;
    background:transparent;
    border: 0;
    overflow-x:hidden;
    transition: color 0.5s ease;
    
    &:after{
      content:'';
      position: absolute;
      top: 50%;
      left: 0;
      height:1px;
      width: 0;
      background: $color-dark;
      transition: width 0.4s ease;
    }
    &:hover{
      cursor:pointer;
      color: rgba($color-dark, 0.75);
      transition: color 0.5s ease;
    }
    &:hover:after{
      width:100%;
      transition: width 0.4s ease;
    }
    // Yeah... I know
    &:focus{
      outline: 0;
    }
  }
  // If is Sliding, prevent 
  // double clicks
  .is-sliding &{
    pointer-events:none;
  }
}

//-------------------------------
//  Slides
//-------------------------------
.slides{
  position: relative;
  display: block;
  height: 100vh;
  width:100%;
  background: #fff;
  transition: background 1s $ease-cb-4;

  .is-sliding &{
    background: $color-trans-bg;
    transition: background 0.3s $ease-cb-4;
  }
}

//-------------------------------
//  Single Slide
//-------------------------------
$slide-content-size-sm: 95%;
$slide-content-size: 80%;

.slide{
  z-index: -1;
  padding: 0;
  position: absolute;
  width: 100%;
  height: 100vh;
  transition: z-index 1s ease;
  
  // Bring active layer to foreground
  &.is-active{
    z-index: 19;
    transition: z-index 1s ease;
  }

  &__content{
    position: relative;
    margin: 0 auto;
    height: $slide-content-size-sm;
    width: $slide-content-size-sm;
    top: (100% - $slide-content-size-sm) / 2;
    
    @media (min-width: $mq-med){
      height: $slide-content-size;
      width: $slide-content-size;
      top: (100% - $slide-content-size) / 2;
    }
  }

  &__header{
    z-index: 9;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    overflow-y: hidden;
    transform: translateX(5%);
    
    @media (min-width: $mq-med){
      transform: translateX(5%);
    }
  }

  &__title{
    font-size: 0.7rem;
    font-weight: 300;
    color: $color-dark;
    overflow-y: hidden;
    background-color: #e31d25;
    
    @media (min-width: $mq-med){
      font-size: 1.7rem;
    }
     
    // Slice text animation
    .title-line{
      display: block;
      overflow-y: hidden;

      span{
        display: inline-block;
        transform:translate3d(0,140%,0);
        opacity: 0;
        transition: transform 0.4s ease, opacity 0.8s ease;
        @include nth-trans-delay(2, 0.15s);
      }

      .is-active & span{
        transform:translate3d(0,-3%,0);
        opacity: 1;
        transition: transform 0.6s $ease-cb-3, opacity 0.1s ease;
      }
      .is-active &:nth-of-type(2n) span{
        transition-delay:0.2s;
      }
    }
  }
  
  // Image wrapper
  &__figure{
    z-index: 7;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 100%;
    width: 100%;
    //overflow-y: hidden;
    transition: transform 0.5s $ease-cb-2;
     
    // Scale Image
    .is-sliding &{
      transform: scale(0.8);
      transition: transform 0.5s $ease-cb-2;
    }
  }
  &__img{
    position: relative;
    display: block;
    background-size: cover;
    background-attachment: fixed;
    background-position: 50%;
     -webkit-backface-visibility: hidden;
     //will-change:height;
    height: 0%;
    width: 100%;
    filter: grayscale(0%);
    transition: height 1s 1.4s $ease-cb-2, filter 0.4s 0.1s ease;
      
    .is-active &{
      height: 100%;
      opacity: 1;
      transition: height .5s .3s $ease-cb-3, filter 0.4s 0.1s ease;
    }
    .is-sliding &{
      filter: grayscale(100%);
    }
  }
}


.btnempezar {
  float: left;
  text-align: center;
  padding: 12px;
  margin: 0.5px;
  text-decoration: none;
  font-size: 19px; 
  line-height: 25px;
  border-radius: 4px;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
}
.btnempezar:hover {
  color: rgb(27, 27, 27);
	background-color: #e9e4e4;
}
.btnempezar:active {
	position:relative;
	top:1px;
}

        